import React, { Component } from "react";
import TextLoop from "react-text-loop";

class About extends Component {
    render() {
        let title = 'We are ',
            description = 'Tequila Capital is your tech venture party host, pouring bold investments into startups that pack a punch. We serve up success straight up, no chasers - all we ask is for your idea and 40% equity.';
        return (
            <React.Fragment>
                <div className="about-wrapper">
                    <div className="container">
                        <div className="row row--35 align-items-center">

                            <div className="col-lg-5 col-md-12">
                                <div className="thumbnail">
                                    <img className="w-100" src="/assets/images/about/landing_page2.png" alt="Tequila Bottle exploding of innovation" />
                                </div>
                            </div>

                            <div className="col-lg-7 col-md-12">
                                <div className="about-inner inner">
                                    <div className="section-title">
                                        <h3 className="">{title}
                                            <TextLoop style={{ overflow: "hidden", maxWidth: "100%", whiteSpace: "normal"}}>
                                                <span className="theme-gradient" style={{ wordWrap: "break-word", overflowWrap: "break-word", display: "inline-block", maxWidth: "100%", whiteSpace: "nowrap" }}>Tequila-Tripping Techies</span>
                                                <span className="theme-gradient" style={{ wordWrap: "break-word", overflowWrap: "break-word", display: "inline-block", maxWidth: "100%", whiteSpace: "nowrap" }}>Agave-Adoring Analysts</span>
                                                <span className="theme-gradient" style={{ wordWrap: "break-word", overflowWrap: "break-word", display: "inline-block", maxWidth: "100%", whiteSpace: "nowrap" }}>Mezcal-Musing Masterminds</span>
                                                <span className="theme-gradient" style={{ wordWrap: "break-word", overflowWrap: "break-word", display: "inline-block", maxWidth: "100%", whiteSpace: "nowrap" }}>Shot-Savoring Strategists</span>
                                                <span className="theme-gradient" style={{ wordWrap: "break-word", overflowWrap: "break-word", display: "inline-block", maxWidth: "100%", whiteSpace: "nowrap" }}>Liquor-Loving Logicians</span>
                                            </TextLoop>{" "}
                                        </h3>
                                        <p className="description">{description}</p>
                                    </div>
                                    <div className="row mt--30 mt_sm--10">
                                        <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                            <div className="about-us-list">
                                                <h3 className="title">Who We Are</h3>
                                                <p>We're a bunch of drunks straight from the top-shelf strategy & VC bars, intoxicated by the potential of groundbreaking tech. Our investment style? We are outdrinking the competition.</p>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                            <div className="about-us-list">
                                                <h3 className="title">Why We're Here</h3>
                                                <p>We're mixing up the potent cocktail of tech innovation and capital. Our straight-up strategy empowers startups, fostering innovation and growth - one shot at a time. Join the round at Tequila Capital, toasting your success.</p>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default About;