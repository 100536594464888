import React, { Component } from "react";


const PortfolioList = [
    {
        image: 'image-linkedin-unhumbled',
        category: 'LinkedIn unhumbled',
        title: 'Make LinkedIn Bearable Again',
        link: 'https://chromewebstore.google.com/detail/linkedin-unhumbled/mbnjnemapiheibpchdcgjkmkbcckkikp?authuser=0&hl=de'
    },
    {
        image: 'image-ai-decision-roulette',
        category: 'AI Decision Roulette',
        title: 'Your ultimate decision maker',
        link: 'https://decision-roulette.com'
    },
    {
        image: 'image-2',
        category: 'GenAI Bootcamp',
        title: 'Realize your own ideas with AI',
        link: 'https://www.genai-bootcamp.com'
    },
    {
        image: 'image-4',
        category: 'verantwortungsVOLL',
        title: 'Party for a good cause',
        link: 'https://www.verantwortungs-voll.de'
    }
]

class PortfolioOne extends Component{
    render(){
        return(
            <React.Fragment>
                {PortfolioList.map((value , index) => (
                    <div className="col-lg-3" key={index}>
                        <div className="portfolio">
                            <div className="thumbnail-inner">
                                <div className={`thumbnail ${value.image}`}></div>
                                <div className={`bg-blr-image ${value.image}`}></div>
                            </div>
                            <div className="content">
                                <div className="inner">
                                    <p>{value.category}</p>
                                    <h4><a href={value.link}>{value.title}</a></h4>
                                    <div className="portfolio-button">
                                        <a className="rn-btn" href={value.link}>Link</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
               
            </React.Fragment>
            
        )
    }
}
export default PortfolioOne;