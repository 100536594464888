import React, { Component } from "react";
import { Link } from 'react-router-dom';

class Header extends Component {
    constructor(props) {
        super(props);
        window.addEventListener('load', function () {
            console.log('All assets are loaded')
        })
    }

    render() {
        const { logo, color = 'default-color' } = this.props;
        let logoUrl;

        logoUrl = <img src="/assets/images/logo/tequila-shot-logo.png" alt="Exploding Tequila Capital Shot" />;

        return (
            <header className={`header-area header--transparent ${color}`}>
                <div className="header-wrapper" id="header-wrapper">
                    <div className="header-left">
                        <div className="logo">
                            <a href="/">
                                {logoUrl}
                            </a>
                        </div>
                    </div>
                    <div className="header-right">
                        <div className="header-btn">
                            <a href="mailto:hello@tequila-capital.com" className="rn-button-style--2">Reach out</a>
                        </div>
                    </div>
                </div>
            </header>
        )
    }
}
export default Header;