import React from "react";
import { FaFacebookF, FaLinkedinIn, FaTwitter } from "react-icons/fa";

let data = [
    {
        images: 'dackel',
        title: 'Dirk Dackel',
        designation: 'Hangover Doggo',
        socialNetwork: [ {}]
    },
    {
        images: 'moeslmann',
        title: 'Möslmann',
        designation: 'Global Head of Partymäuse',
        socialNetwork: [
            {
                icon: <FaLinkedinIn />,
                url: 'https://www.linkedin.com/in/lena-roeslmaier/'
            }
        ]
    },
    {
        images: 'flex',
        title: 'Dr. Flex',
        designation: 'No one really knows what he is doing tbh',
        socialNetwork: [
            {
                icon: <FaLinkedinIn />,
                url: 'https://www.linkedin.com/in/felixgraeber/'
            }
        ]
    },
    {
        images: 'oliver',
        title: 'Oliver',
        designation: 'Seducing squirrel - so sales I guess?',
        socialNetwork: [
            {}
        ]
    },
];

export default data;