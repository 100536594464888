import React, { Component } from "react";

const ServiceList = [
    {
        icon: "01",
        title: "We Invest Like We Drink: Boldly",
        description: "At Tequila Capital, we serve shots of bold investment as robust as our favorite drink. No watered-down ideas, only high-proof innovation!"
    },
    {
        icon: "02",
        title: "We Are Shotters, Not Chasers",
        description: "At Tequila Capital, we're shotters, not chasers - going all-in on strong tech innovations, no dilution or backtracking allowed!"
    },
    {
        icon: "03",
        title: "Pouring More Than Just Capital",
        description: "At Tequila Capital, we're not just investors, we're seasoned connoisseurs of innovation, leaving competition dizzy in our trail!"
    }
    
]

class ServiceOne extends Component{
    render(){
        return(
            <React.Fragment>
                <div className="row">
                    {ServiceList.map( (val , i) => (
                        <div className="col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                            <div className="service service__style--1">
                                <div className="icon">
                                    <img src={`/assets/images/icons/icon-${val.icon}.png`} alt="Tequila Capital"/>
                                </div>
                                <div className="content">
                                    <h4 className="title">{val.title}</h4>
                                    <p >{val.description}</p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </React.Fragment>
        )
    }
}
export default ServiceOne;