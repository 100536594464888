import React, { Component } from "react";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";

import SliderOne from "../component/slider/SliderOne";
import CounterOne from "../elements/counters/CounterOne";
import About from "../component/HomeLayout/homeOne/About";
import TeamOne from "../blocks/team/TeamOne";
import Helmet from "../component/common/Helmet";
import PortfolioOne from "../elements/portfolio/PortfolioOne";

class MainDemo extends Component {
    render() {
        // const PostList = BlogContent.slice(0, 3);
        return (
            <div className="active-dark">
                <Helmet pageTitle="Tequila Capital" />
                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

                {/* Start Slider Area   */}
                <div className="slider-wrapper">
                    <SliderOne />
                </div>
                {/* End Slider Area   */}

                {/* Start About Area */}
                <div className="about-area about-position-top pb--120 bg_color--1">
                    <About />
                </div>
                {/* End About Area */}

                {/* Start Portfolio Area */}
                <div className="portfolio-area ptb--120">
                    <div className="portfolio-sacousel-inner">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="section-title text-center mb--30">
                                        <h2 className="title">Our Portfolio</h2>
                                        <p>Investing in truly disruptive projects that are making a difference!</p>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <PortfolioOne />
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Portfolio Area */}

                {/* Start Team Area  */}
                <div className="rn-team-wrapper ptb--120 bg_color--1">
                    <div className="rn-team-area">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="section-title text-center mb--30">
                                        <h3>Pouring Success: Your Investment Barkeepers</h3>
                                        <p></p>
                                    </div>
                                </div>
                            </div>
                            <TeamOne column="col-lg-3" teamStyle="" item="4" />
                        </div>
                    </div>
                </div>
                {/* End Team Area  */}

                {/* Start CounterUp Area */}
                <div className="rn-counterup-area pt--25 pb--110">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center">
                                </div>
                            </div>
                        </div>
                        <CounterOne />
                    </div>
                </div>
                {/* End CounterUp Area */}

                {/* Start Brand Area 
                <div className="rn-brand-area brand-separation pb--120">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center">
                                    <h3 className="fontWeight500">Professionals from many organizations love our bootcamp!</h3>
                                </div>
                                <BrandTwo />
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Brand Area */}
                
                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

                <Footer />

            </div>
        )
    }
}
export default MainDemo;