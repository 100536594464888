// React Required
import React, { Component, useEffect } from 'react';
import ReactDOM from 'react-dom';

// Create Import File
import './index.scss';

import PageScrollTop from './component/PageScrollTop';

// Dark Home Layout 
import DarkMainDemo from './dark/MainDemo';

// Element Layout
import ServiceDetails from "./elements/ServiceDetails";
import error404 from "./elements/error404";

// Blocks Layout
import About from "./elements/About"
import { BrowserRouter, Switch, Route, useLocation  } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';

import ReactGA from "react-ga4";

ReactGA.initialize("G-TT3QRHG70P")

const SendPageView = () => {
    const location = useLocation()

    useEffect(() => {
        ReactGA.event({
            category:"Page View",
            action: "Navigated to " + location.pathname,
        });
    }, [location]);
    return null;
}

class Root extends Component{
    render(){
        return(
            <BrowserRouter basename={'/'}>
                <PageScrollTop>
                    <Switch>
                        <Route exact path={`${process.env.PUBLIC_URL}/`} component={DarkMainDemo}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/imprint`} component={About}/>
                        <Route path={`${process.env.PUBLIC_URL}/404`} component={error404}/>
                        <Route component={error404}/>

                    </Switch>
                </PageScrollTop>
            </BrowserRouter>
        )
    }
}

ReactDOM.render(<Root/>, document.getElementById('root'));
serviceWorker.register();