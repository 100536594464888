import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import { Link } from "react-router-dom"

class ServiceDetails extends Component {
    constructor() {
        super()
        this.state = {
            isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }
    openModal() {
        this.setState({ isOpen: true })
    }
    render() {
        return (
            <React.Fragment>

                {/* Start Pagehelmet  */}
                <PageHelmet pageTitle='Service Details' />
                {/* End Pagehelmet  */}

                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

                {/* Start Breadcrump Area */}
                <div className="rn-page-title-area pt--120 pb--190 bg_image bg_image--36" data-black-overlay="5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="rn-page-title text-center pt--100">
                                    <h2 className="title theme-gradient">4-week GenAI curriculum </h2>
                                    <p>Intense, hands-on up-skilling to enable you to unlock Generative AI</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Breadcrump Area */}

                {/* Start Columns Area  */}
                <div className="active-dark">
                    <div className="rn-columns-area ptb--120 bg_color--1">
                        <div className="container">
                            <div className="section-title text-center service-style--3 mb--30">
                                <h2 className="title">Curriculum - Overview</h2>
                            </div>
                            <div className="row">
                                <div className="col-lg-3 col-md-6 col-12">
                                    <div className="single-column custom-color">
                                        <h4 className="tilte">Week 1</h4>
                                        <p>We guide you through the jungle of different programming languages, environments, and tools so you can produce results from day 1. Master GenAI workflows, analyze and visualize data, and build your first website from scratch with the help of AI. This week serves as your comprehensive entryway to the vast world of software development and AI proficiency.</p>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 col-12 mt_sm--30">
                                    <div className="single-column custom-color custom-color--1">
                                        <h4 className="tilte">Week 2</h4>
                                        <p>Unravel the intricacies of modern web application architecture as you dive into Python's Flask framework. Learn how to build a ChatGPT powered app, enhance it with advanced Flask features, and ensure its robustness with debugging and testing practices. An immersive week filled with hands-on experiences that transform you into a capable web developer.</p>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 col-12 mt_sm--30 mt_md--30">
                                    <div className="single-column custom-color custom-color--2">
                                        <h4 className="tilte">Week 3</h4>
                                        <p>Venture into the realm of cloud services and understand how to deploy your own web app. Discover LangChain, a groundbreaking language model application framework, and learn to build intelligent agents. Experience first-hand how modern software development practices and Generative AI enable you to generate and deploy your ideas in hours.</p>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 col-12 mt_sm--30 mt_md--30">
                                    <div className="single-column custom-color custom-color--3">
                                        <h4 className="tilte">Week 4</h4>
                                        <p>This week, your personal idea takes the spotlight. Test its potential, develop your first prototype, and expose it to potential users. Learn to iterate based on feedback and perfect your application. Witness your brainchild go live in public, marking the culmination of your transformation into a proficient software developer that confidently leverages Generative AI to drive results. </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Start Columns Area  */}

                    {/* Start Page Wrapper */}
                    <div className="rn-service-details ptb--120 bg_color--1">
                        <div className="container">
                            <div className="section-title text-center service-style--3 mb--30">
                                <h2 className="title">Curriculum - Details</h2>
                            </div>
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="service-details-inner">
                                        <div className="inner">
                                            {/* Week 1 */}
                                            <div className="row sercice-details-content pb--80 align-items-center">
                                                <div className="col-lg-4 col-12">
                                                    <div className="thumb">
                                                        <img className="w-100" src="/assets/images/service/week1.png" alt="Service Images" />
                                                    </div>
                                                </div>
                                                <div className="col-lg-8 col-12">
                                                    <div className="details mt_md--30 mt_sm--30">
                                                        <h3 className="title"><span className="theme-gradient">Week 1:</span> Mastering the Fundamentals</h3>
                                                        <ul className="liststyle">
                                                            <li><b>Day 1 |</b> Bootcamp objectives: Kickstart your transformative journey</li>
                                                            <li><b>Day 2 |</b> Tools for efficient software development: Amplify your productivity</li>
                                                            <li><b>Day 3 |</b> Generative AI and software development: A hands-on introduction</li>
                                                            <li><b>Day 4 |</b> GenAI Project 1: Master rapid data cleaning, analysis, and visualization with GenAI</li>
                                                            <li><b>Day 5 |</b> GenAI Project 2: From idea to a React website in less than an hour</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* End Week 1*/}

                                            {/* Week 2 */}
                                            <div className="row sercice-details-content align-items-center">
                                                <div className="col-lg-8 col-12 order-2 order-lg-1">
                                                    <div className="details mt_md--30 mt_sm--30">
                                                        <h3 className="title"><span className="theme-gradient">Week 2:</span> Building & Optimizing Web Apps</h3>
                                                        <ul className="liststyle">
                                                            <ul className="liststyle">
                                                                <li><b>Day 1 |</b> Unveiling web application architecture: Deep dive into React and Flask</li>
                                                                <li><b>Day 2 |</b> GenAI Project 3: Build a modern web app with React and Flask</li>
                                                                <li><b>Day 3 |</b> Advanced React & Flask: Enhancing your app with robust features</li>
                                                                <li><b>Day 4 |</b> Debugging and testing: Ensuring your app's robustness</li>
                                                                <li><b>Day 5 |</b> GenAI Project 4: Reflecting on a week of web development with your own project</li>
                                                            </ul>

                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 col-12 order-1 order-lg-2">
                                                    <div className="thumb position-relative">
                                                        <img className="w-100" src="/assets/images/service/week2.png" alt="Service Images" />
                                                    </div>
                                                </div>
                                            </div>
                                            {/* End Week 2 */}

                                            {/* Week 3 */}
                                            <div className="row sercice-details-content pb--80 align-items-center">
                                                <div className="col-lg-4 col-12">
                                                    <div className="thumb">
                                                        <img className="w-100" src="/assets/images/service/week3.png" alt="Service Images" />
                                                    </div>
                                                </div>
                                                <div className="col-lg-8 col-12">
                                                    <div className="details mt_md--30 mt_sm--30">
                                                        <h3 className="title"><span className="theme-gradient">Week 3:</span> Mastering the Fundamentals</h3>
                                                        <ul className="liststyle">
                                                            <ul className="liststyle">
                                                                <li><b>Day 1 |</b> Introduction to cloud services: Deploying your own website or app</li>
                                                                <li><b>Day 2 |</b> GenAI Project 5: Launching your first personal landing page</li>
                                                                <li><b>Day 3 |</b> Introduction to LangChain: Developing apps powered by language models</li>
                                                                <li><b>Day 4 |</b> GenAI Project 6: Building your very own intelligent agents</li>
                                                                <li><b>Day 5 |</b> GenAI Project 7: Launching your first intelligent agent</li>
                                                            </ul>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* End Week 3*/}

                                            {/* Week 4 */}
                                            <div className="row sercice-details-content align-items-center">
                                                <div className="col-lg-8 col-12 order-2 order-lg-1">
                                                    <div className="details mt_md--30 mt_sm--30">
                                                        <h3 className="title"><span className="theme-gradient">Week 4:</span> Building & Optimizing Web Apps</h3>
                                                        <ul className="liststyle">
                                                            <li><b>Day 1 |</b> Your idea, your prototype: Kicking off your personal GenAI project 8</li>
                                                            <li><b>Day 2 |</b> User feedback and iteration: Refining your application</li>
                                                            <li><b>Day 3 |</b> Hands-on: Preparing your app for the public domain</li>
                                                            <li><b>Day 4 |</b> Go live: Launching your application</li>
                                                            <li><b>Day 5 |</b> The culmination: Reflecting on your journey to proficiency</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 col-12 order-1 order-lg-2">
                                                    <div className="thumb position-relative">
                                                        <img className="w-100" src="/assets/images/service/week4.png" alt="Service Images" />
                                                    </div>
                                                </div>
                                            </div>
                                            {/* End Week 4 */}

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* End Page Wrapper */}

                    {/* Start Back To Top */}
                    <div className="backto-top">
                        <ScrollToTop showUnder={160}>
                            <FiChevronUp />
                        </ScrollToTop>
                    </div>
                    {/* End Back To Top */}

                    <Footer />
                </div>

            </React.Fragment>
        )
    }
}
export default ServiceDetails;